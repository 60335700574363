export default class Main {
  constructor() {
    let bd = document.getElementsByTagName("html")[0];
    let app = document.querySelector(".app");

    if (app) {
      let page = app.dataset.page;
      this.page = page;
    }
    this.bd = bd;
  }
  render() {
    this.commonFuncion();
    $(".js-play").on("click", $.proxy(this.initYouTube, this));
    if (this.page === "home") {
      $(".js-play").on("click", $.proxy(this.initYouTube, this));
      var swiper = new Swiper(".swiper-intro", {
        navigation: {
          nextEl: ".swiper-next-text",
          prevEl: ".swiper-prev-text",
        },
      });

      var swiper = new Swiper(".swiper-monferrato", {
        slidesPerView: 1.5,
        spaceBetween: 20,
        navigation: {
          nextEl: ".swiper-next-text",
          prevEl: ".swiper-prev-text",
        },
      });
      var swiper = new Swiper(".swiper-experince", {
        slidesPerView:3,
        spaceBetween: 20,
        pagination: {
          el: ".swiper-pagination",
          clickable: true,
        },
      });
      this.createVoto();
    }
    if (this.page === "degustazione") {
      $(".js-play").on("click", $.proxy(this.initYouTube, this));
      var swiper = new Swiper(".swiper-visita", {
        slidesPerView: 3.5,
        spaceBetween: 20,
        pagination: {
          el: ".swiper-pagination",
          clickable: true,
        },
      });
      this.createVoto();
    }
    if(this.page === "accoglienza"){
      var swiper = new Swiper(".js-swiper-accoglienza", {
        slidesPerView: "auto",
        spaceBetween: 20,
        navigation: {
          nextEl: ".swiper-next-text",
          prevEl: ".swiper-prev-text",
        },
      });
    }
  }
  commonFuncion() {
    $(".single-faq").on("click", (e) => {
      let target = $(e.target).parent();
      target.toggleClass("open");
      $(".body-faq", target).toggleClass("open");
    });

    $(".btn-open-menu").on("click", (e) => {
      $(".container-menu-mobile").addClass("open");
      this.bd.classList.add("overflow");
      $(".btn-close").on("click", (e) => {
        $(".container-menu-mobile").removeClass("open");
        this.bd.classList.remove("overflow");
      });
    });
  }


  createVoto(){
    let rec = document.querySelectorAll('.single-recensione');
    rec.forEach(el=>{
      let vote = el.dataset.vote;
        for(let x = 0; x<vote;x++){
          let star = '<i class="fas fa-star"></i>';
          $(".wrapper-stars", el).append(star);
        }
        let emptyStar = 5- vote;
        for(let y = 0; y<emptyStar;y++){
          let empt_star = '<i class="far fa-star"></i>';
          $(".wrapper-stars", el).append(empt_star);
        }
    })
  }

  //init video youtube
  initYouTube(e) {
    // console.log("video youtube");
    // $(".preview-video").hide();
    var tag = document.createElement("script");

    let modal = document.querySelector(".js-modal-video");
    modal.classList.add("open");

    $(".player-video").show();
    let videoID = e.target.dataset.video;
    console.log(videoID);
    var player;
    player = new YT.Player("player", {
      videoId: `${videoID}`,
      loadVideoById: `${videoID}`,
      //playlist: `${videoID}`,
      fov: 100,
      rel: 0,
      loop: 1,
      showinfo: 0,
      //playerVars: { 'playlist': `${videoID}`, 'listType': 'playlist', 'autoplay': 1, 'controls': 1, 'loop': 1 },
      events: {
        onReady: (e) => {
          e.target.playVideo();
        },
      },
    });
    modal.querySelector(".close").addEventListener("click", (e) => {
      modal.classList.remove("open");
      $("#player").remove();
      player.stopVideo();
      let newPlayer = document.createElement("div");
      newPlayer.setAttribute("id", "player");
      $(".player-video").append(newPlayer);
    });
  }
}

